.gallery-item {
  filter: grayscale(100%);
  transition: filter 0.5s ease;
  /* Добавьте другие стили для изображения, такие как размеры и рамка */
}

/* Стили для цветного изображения */
.colorful {
  filter: none;
  transition: filter 0.5s ease;
}

.gallery {
  margin-top: 50px;
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.first__block {
  display: flex;
  justify-content: center;
  gap: 2%;
}

.first__block-item {
  max-width: 24%;
  border-radius: 1%;
}

.second__block {
  display: flex;
  justify-content: center;
  gap: 2%;
}

.second__block-item {
  max-width: 50%;
  border-radius: 1%;
}

.third__block {
  display: flex;
  justify-content: center;
  gap: 2%;
}

.third__block-item {
  max-width: 24%;
  border-radius: 1%;
}

@media (max-width: 980px) {
  .gallery {
    gap: 15px;
  }
}

@media (max-width: 700px) {
  .gallery {
    gap: 30px;
  }
  .first__block {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .first__block-item {
    max-width: 100%;
  }
  .second__block {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .second__block-item {
    max-width: 100%;
  }
  .third__block {
    display: flex;
    flex-direction: column;
    gap: 30px;
  }
  .third__block-item {
    max-width: 100%;
  }
}
