.slider-container {
  max-width: 800px; /* Максимальная ширина для десктопа */
  /* max-height: 400px; */
  margin: 0 auto; /* Центрирование на странице */

  @media (max-width: 768px) {
    /* Медиа-запрос для мобильных устройств */
    max-width: 100%; /* Полная ширина для мобильных устройств */
  }

  video {
    width: 100%; /* Заполнение 100% ширины родительского контейнера */
    height: auto; /* Автоматическое масштабирование высоты */
  }
}
