/* .menu {
  padding: 30px 0;
}

.menu__nav {
  display: flex;
  justify-content: center;
  gap: 50px;
}

.menu__nav li {
  cursor: pointer;
}

.nav-item {
  position: relative;
  color: black; 
  text-decoration: none;
  overflow: hidden;
}

.nav-item::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px; 
  bottom: 0px;
  right: 0;
  background-color: black; 
  transform: scaleX(0); 
  transform-origin: right center;
  transition: transform 0.3s; 
}

.nav-item:hover::after {
  transform: scaleX(1);
  transform-origin: left center;
} */

/* Стили для бургер-меню */
.burger-menu {
  /* background-color: #002f55; */
  /* color: #fff; */
  background-color: #fff;
  padding: 20px 0;
}

.menu-header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px;
}

.logo {
  font-size: 24px;
}

.burger-icon {
  cursor: pointer;
  display: none;
  flex-direction: column;
  justify-content: space-between;
  width: 30px;
  height: 20px;
}

.bar {
  width: 100%;
  height: 3px;
  background-color: black;
  transition: transform 0.3s ease-in-out;
}

.menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
}

.menu li {
  margin-right: 20px;
  font-size: 18px;
  cursor: pointer;
}

.nav-item {
  position: relative;

  text-decoration: none;
  overflow: hidden;
}

.nav-item::after {
  content: '';
  position: absolute;
  width: 100%;
  height: 2px;
  bottom: 0px;
  right: 0;
  background-color: black;
  transform: scaleX(0);
  transform-origin: right center;
  transition: transform 0.3s;
}

.nav-item:hover::after {
  transform: scaleX(1);
  transform-origin: left center;
}

@media (max-width: 980px) {
  .burger-menu {
    /* background-color: #002f55;
    color: #fff; */
    background-color: #fff;
    padding: 10px 0;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 999;
    transition: top 0.3s;
  }

  .burger-icon {
    display: flex;
  }

  .menu {
    flex-direction: column;
    align-items: center;
    display: none;
    height: 0;
    opacity: 0;
    overflow: hidden;
    transition: height 0.3s ease-in-out, opacity 0.3s ease-in-out;
  }

  .menu.open {
    padding: 30px 0;
    display: flex;
    gap: 30px;
    height: auto;
    opacity: 1;
  }

  .menu__box {
    transition-duration: 0.25s;
    visibility: hidden;
    left: -100%;
  }

  .bar {
    transition: transform 0.25s;
  }

  .burger-icon.open .bar:nth-child(1) {
    transform: rotate(45deg);
    transform-origin: 0px 3px;
  }

  .burger-icon.open .bar:nth-child(2) {
    display: none;
  }

  .burger-icon.open .bar:nth-child(3) {
    transform: rotate(-45deg);
    transform-origin: 6px 4px;
  }
}
