.header {
  background-color: #bad8e0;
  padding: 50px 0;
}

.header__inner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  text-align: center;
  padding: 50px 0;
}

.header__img {
  flex-basis: 50%;
  max-width: 30%;
  border-radius: 2%;
}

.header__inner-item {
  max-width: 400px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.header__inner-item h1 {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  font-size: 30px;
}

.header__inner-item h2 {
  font-family: 'Playfair Display', serif;
  font-weight: 400;
  font-size: 25px;
}

@media (max-width: 600px) {
  .header__inner {
    flex-direction: column;
    gap: 30px;
  }
  .header__img {
    max-width: 90%;
  }
  /* .header__inner-item h1 {
    font-size: 20px;
  }

  .header__inner-item h2 {
    font-size: 15px;
  } */
}
