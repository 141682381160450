.about {
  /* background-image: url('../../assets/img/foni-club.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  padding: 70px 0; */
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.about__title {
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  font-weight: 400;
}

.about__text {
  margin-top: 30px;
  max-width: 700px;
}
