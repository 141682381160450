/* .slider__item {
  padding: 0 10px;
} */
.slick-prev:before,
.slick-next:before {
  color: black;
}

.slider__item-image {
  min-width: 100%;
  max-height: 600px;
  background-size: cover;
  background-position: center;
}

.slick-next {
  right: -20px;
  max-width: 120%;
}

.slick-prev {
  left: -20px;
}

.carousel {
  max-width: 1220px;
  margin: 0 auto;
  padding: 0 10px;
  margin-bottom: 50px;
}

@media (max-width: 980px) {
  .slider__item-image {
    max-height: 400px;
  }
}

@media (max-width: 750px) {
  .slider__item-image {
    max-height: 250px;
  }
}

@media (max-width: 600px) {
  .slick-next,
  .slick-prev {
    display: none !important;
  }

  .slider__item-image {
    max-height: 150px;
  }
}
