.poem {
  background-color: #a7c8d7;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.poem h1 {
  text-align: center;
  font-family: 'Playfair Display', serif;
  font-size: 30px;
  font-weight: 400;
}

.poem p {
  max-width: 500px;
  margin-top: 30px;
  text-align: justify;
}

@media (max-width: 700px) {
  .poem p {
    max-width: 300px;
  }
}
