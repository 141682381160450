*,
*::after,
*::before {
  box-sizing: content-box;
}

* {
  -webkit-tap-highlight-color: transparent;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  line-height: 1.17;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
  text-decoration: none;
  text-transform: none;
  color: inherit;
}

ul,
li {
  padding: 0;
  margin: 0;
  list-style: none;
}

a,
p,
h1,
h2,
h3,
h4,
h5,
span {
  padding: 0;
  margin: 0;
}
